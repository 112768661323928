import React, { useContext, useEffect, useState } from "react"
import * as translations from "../../utils/translations"

import ScruffyInputBackground from "../../images/scruffy__input-background.svg"
import ScruffyTextareaBackground from "../../images/scruffy__textarea-background.svg"
import {
  FormContainer,
  FormFieldset,
  InputEmail,
  InputSelect,
  InputText,
  InputTextarea,
  InputWrapper,
  StyledForm,
  StyledThankYouForm,
  SubmitButton,
  ThankYouFormFieldset,
  ThankYouHeader,
  ThankYouText,
  ValidationText,
  VisuallyHiddenLabel,
} from "./shared/FormStyling"
import { ILocaleContext, LocaleContext } from "../../context/localeContext"
import { getUrlLocation } from "../../utils/getUrlLocation"

type MatterEmails = {
  catering: string
  consumer: string
  hotel: string
  webshop: string
  urbanDeliPaVag: string
  economy: string
  market: string
  subscription: string
}

type LocationEmails = {
  nytorget: string
  sickla: string
  sveavagen: string
  Hagastaden: string
}

const matterEmails: MatterEmails = {
  catering: "catering@spiritualparcel.com",
  subscription: "catering@spiritualparcel.com",
  consumer: "konsumentkontakt@spiritualparcel.com",
  hotel: "hello@hotelwith.se",
  webshop: "webbutik@spiritualparcel.com",
  urbanDeliPaVag: "urbandelipavag@spiritualparcel.com",
  economy: "ekonomi@spiritualparcel.com",
  market: "marknad@spiritualparcel.com",
}

const restaurantEmails: LocationEmails = {
  nytorget: "bokningnytorget@spiritualparcel.com",
  sickla: "bokningsickla@spiritualparcel.com",
  sveavagen: "bokningsveavagen@spiritualparcel.com",
  Hagastaden: "restauranghagastaden@spiritualparcel.com",
}

const shopEmails: LocationEmails = {
  nytorget: "butiknytorget@spiritualparcel.com",
  sickla: "butiksickla@spiritualparcel.com",
  sveavagen: "butiksveavagen@spiritualparcel.com",
  Hagastaden: "butikhagastaden@spiritualparcel.com",
}
type FormValidation = {
  matter: null | boolean
  location: null | boolean
  name: null | boolean
  email: null | boolean
  message: null | boolean
}

type ContactUsForm = {
  matter?: string
}

export const ContactUsForm: React.FC<ContactUsForm> = ({ matter }) => {
  const { locale } = useContext(LocaleContext) as ILocaleContext
  const urlLocation = getUrlLocation()
  const translation =
    locale === "en"
      ? translations.contactUsForm.en
      : translations.contactUsForm.sv

  const [validations, setValidations] = useState<FormValidation>({
    matter: null,
    location: null,
    name: null,
    email: null,
    message: null,
  })
  const [showLocation, setShowLocation] = useState(false)
  const [showThankYou, setShowThankYou] = useState(false)
  const [url, setUrl] = useState("")
  const [emailReceiver, setEmailReceiver] = useState("")
  const [values, setValues] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    matter: matter ? matter : "",
    location: "",
    message: "",
  })

  const handleValidation = (event: any) => {
    setValidations({
      ...validations,
      [event.target.name]: !event.target.validity.valid,
    })
  }

  const handleSubmit = (e: any) => {
    Object.keys(validations).forEach(
      (key) =>
        (validations[key as keyof FormValidation] =
          validations[key as keyof FormValidation] === null ||
          validations[key as keyof FormValidation] === true
            ? true
            : false)
    )
    setValidations({ ...validations })
  }

  const handleChange = (event: any) => {
    setValues({ ...values, [event.target.name]: event.target.value })
    if (validations[event.target.name as keyof FormValidation] === true) {
      handleValidation(event)
    }
  }

  const handleShowForm = (event: any) => {
    setShowThankYou(false)
  }

  useEffect(() => {
    setShowThankYou(location.search === "?ThankYou")
    setUrl(location.href ? location.origin + location.pathname : "")
  }, [])

  useEffect(() => {
    setShowLocation(values.matter === "shop" || values.matter === "restaurant")
  }, [values.matter])

  useEffect(() => {
    if (values.matter === "shop") {
      setEmailReceiver(shopEmails[values.location as keyof LocationEmails])
    } else if (values.matter === "restaurant") {
      setEmailReceiver(
        restaurantEmails[values.location as keyof LocationEmails]
      )
    } else {
      setEmailReceiver(matterEmails[values.matter as keyof MatterEmails])
    }
  }, [values.matter, values.location])

  return (
    <FormContainer>
      {showThankYou && (
        <StyledThankYouForm>
          <ThankYouHeader>{translation.thankYouHeader}</ThankYouHeader>
          <ThankYouText>{translation.thankYouText}</ThankYouText>
          <ThankYouFormFieldset>
            <SubmitButton
              type="submit"
              value="Nytt Meddelande"
              onClick={handleShowForm}
            />
          </ThankYouFormFieldset>
        </StyledThankYouForm>
      )}
      <StyledForm
        opacity={showThankYou}
        action={`https://formsubmit.co/${emailReceiver}`}
        // action={`https://formsubmit.co/it@spiritualparcel.com`}
        method="POST"
        onSubmit={handleSubmit}
      >
        {/* // Only needed for auto replay */}
        {/* <input
          type="hidden"
          name="_autoresponse"
          value={`Hej ${values.name}, vi har tagit emot ditt mail och svarar så fort vi kan! 
            Med vänliga hälsningar,
            Urban Deli
          `}
        /> */}
        <input type="hidden" name="_template" value="table" />
        <input type="hidden" name="_next" value={url + "?ThankYou#contactus"} />
        <FormFieldset>
          <InputWrapper>
            <input
              type="hidden"
              name="_url"
              value="https://spiritualparcel.com/contact.html"
            ></input>
            <VisuallyHiddenLabel htmlFor="matter">
              {translation.matter}*
            </VisuallyHiddenLabel>
            <InputSelect
              name="matter"
              id="matter"
              required
              //@ts-ignore
              placeholder={`${translation.matter}*`}
              value={values.matter}
              onChange={handleChange}
              onBlur={handleValidation}
              className={validations.matter ? "is-touched" : ""}
            >
              <option value="">{translation.matter}*</option>
              <option value="catering">{translation.cateringMeetings}</option>
              <option value="restaurant">{translation.restaurant}</option>
              <option value="shop">{translation.shop}</option>
              <option value="hotel">{translation.hotel}</option>
              <option value="economy">{translation.economic}</option>
              {urlLocation !== "om-oss" && (
                <option value="subscription">{translation.subscription}</option>
              )}
            </InputSelect>
            <ScruffyInputBackground />
          </InputWrapper>
          {validations.matter && (
            <ValidationText>{translation.choseMatter}</ValidationText>
          )}
        </FormFieldset>
        {showLocation && (
          <FormFieldset>
            <InputWrapper>
              <VisuallyHiddenLabel htmlFor="location">
                {translation.location}*
              </VisuallyHiddenLabel>
              <InputSelect
                name="location"
                id="location"
                required
                value={values.location}
                onChange={handleChange}
                onBlur={handleValidation}
                className={validations.location ? "is-touched" : ""}
              >
                <option value="">{translation.location}*</option>

                <option value="nytorget">Nytorget</option>
                <option value="sveavagen">{translation.sveavagen}</option>
                <option value="sickla">Sickla</option>
                <option value="Hagastaden">Hagastaden</option>
              </InputSelect>
              <ScruffyInputBackground />
            </InputWrapper>
            {validations.location && (
              <ValidationText>{translation.choseLocation}</ValidationText>
            )}
          </FormFieldset>
        )}
        <FormFieldset>
          <InputWrapper>
            <VisuallyHiddenLabel htmlFor="name">
              {translation.name}*
            </VisuallyHiddenLabel>
            <InputText
              type="text"
              name="name"
              id="name"
              required
              placeholder={`${translation.name}*`}
              className={validations.name ? "is-touched" : ""}
              onBlur={handleValidation}
              value={values.name}
              onChange={handleChange}
            />
            <ScruffyInputBackground />
          </InputWrapper>
          {validations.name && (
            <ValidationText>{translation.enterName}</ValidationText>
          )}
        </FormFieldset>
        <FormFieldset>
          <InputWrapper>
            <VisuallyHiddenLabel htmlFor="email">
              {translation.email}*
            </VisuallyHiddenLabel>
            <InputEmail
              type="email"
              name="email"
              id="email"
              required
              placeholder={`${translation.email}*`}
              className={validations.email ? "is-touched" : ""}
              onBlur={handleValidation}
              value={values.email}
              onChange={handleChange}
            />
            <ScruffyInputBackground />
          </InputWrapper>
          {validations.email && (
            <ValidationText>{translation.validEmail}</ValidationText>
          )}
        </FormFieldset>
        <FormFieldset>
          <InputWrapper>
            <VisuallyHiddenLabel htmlFor="message">
              {translation.message}
            </VisuallyHiddenLabel>
            <InputTextarea
              name="message"
              id="message"
              required
              placeholder={`${translation.message}*`}
              value={values.message}
              onChange={handleChange}
              className={validations.message ? "is-touched" : ""}
              onBlur={handleValidation}
            />
            <ScruffyTextareaBackground />
          </InputWrapper>
          {validations.message && (
            <ValidationText>{translation.enterMessage}</ValidationText>
          )}
        </FormFieldset>
        <FormFieldset>
          <SubmitButton
            disabled={showThankYou}
            type="submit"
            value={translation.send}
            onClick={handleSubmit}
          />
        </FormFieldset>
      </StyledForm>
    </FormContainer>
  )
}
