import { graphql } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import SiteWrapper from "../components/SiteWrapper"
import { ILocaleContext, LocaleContext } from "../context/localeContext"
import { LocationPresentation } from "../sections"
import { PageStandard } from "../shared/ContentfulTypes"
import { SectionComponents } from "../utils/SectionSelector"

type PageProps = {
  data: PageStandard
}

const Page: React.FC<PageProps> = ({ data }) => {
  const sections = data.contentfulPage.sections
  const pageInfo = data.contentfulPage
  const [locationIsHotel, setLocationIsHotel] = useState(false)
  const {
    seoTitle,
    seoDescription: { seoDescription },
    slug,
  } = data.contentfulPage

  const { locale } = useContext(LocaleContext) as ILocaleContext

  if (typeof window !== "undefined") {
    let urlHost = window.location.hostname.toLowerCase()
    let urlPath = window.location.pathname
    let targetHost = "spiritualparcel.com"
    // console.log(process.env.GATSBY_IS_DEVELOPMENT)

    let isDevelopment = true

    let oldHotelHost = "hotelwith.se"
    let hotelPath = locale === "en" ? "hotel" : "hotellet"

    if (urlHost === oldHotelHost) {
      window.location.replace(`https://${targetHost}/${hotelPath}`)
    } else if (urlHost !== targetHost && !isDevelopment) {
      window.location.replace(`https://${targetHost}${urlPath}`)
    }
  }

  const hasLocation = data?.contentfulLocation ? true : false

  useEffect(() => {
    if (
      data?.contentfulLocation?.location === "Hotell" ||
      data?.contentfulPage.slug === "hotel"
    ) {
      setLocationIsHotel(true)
    }
  }, [])

  const dropDownLocationMenu = hasLocation && !locationIsHotel

  useEffect(() => {
    document.documentElement?.classList.add("ud-root")
  })
  const currentLocationString = data?.contentfulLocation?.location

  return (
    <SiteWrapper
      isLocation={dropDownLocationMenu}
      currentLocationString={currentLocationString}
      seo={{ seoTitle, seoDescription: seoDescription, slug }}
    >
      {sections.map((section, i) => {
        const Component: React.ElementType =
          SectionComponents[section.__typename]
        if (!SectionComponents[section.__typename]) {
          return <div key={i}>create a component for {section.__typename}</div>
        }
        if (
          section.__typename === "ContentfulSectionLocationPresentation" &&
          hasLocation
        ) {
          return <LocationPresentation key={i} data={data.contentfulLocation} />
        } else {
          return (
            <Component
              location={data?.contentfulLocation?.location}
              index={i}
              data={section}
              pageInfo={pageInfo}
              key={i}
              locale={locale}
            />
          )
        }
      })}
    </SiteWrapper>
  )
}
export const query = graphql`
  query MyPageQuery($id: String, $location: String) {
    contentfulLocation(location: { eq: $location }) {
      node_locale
      ...contentLocation
    }
    contentfulPage(id: { eq: $id }) {
      seoTitle
      node_locale
      seoDescription {
        seoDescription
      }
      slug
      sections {
        ...contentSectionHero
        ...contentSectionSlideshow
        ...contentSectionCalendar
        ...contentfulSectionRichTextColumns
        ...contentSectionFoodMenu
        ...contentSectionFindUs
        ...contentSectionLocationPresentation
        ...contentSectionPromote
        ...contentSectionStepper
        ...contentSectionPromoteNoCta
        ...contentSectionPromote
        ...contentSectionPhotos
        ...contentSectionImageCarousel
        ...contentSectionQuestionsAndAnswers
        ...contentSectionShopifyCards
        ...contentSectionContactUs
        ...contentSectionPresentation
        ...contentSectionButtons
        # ...contentSectionUdOnTheRoadMap
        ...contentSectionRoomSearch
        # ...contentSectionInstagramFeed
        ...contentSectionSubscription
        ...contentSectionInfoPage
        ...contentSectionCampaignPage
      }
    }
  }
`
export default Page
